import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '@/constants';
import { ProgressBar } from '@/components';
import NavigationBar from '@/components/NavigationBar';
import ContactForm from '@/components/Sections/ContactForm';
import Footer from '@/components/Footer';
import { FormData, Response } from '@/interfaces';
import Layout from '@/styles';
import { replaceCommaWithDot } from '@/utils/formatter';

const ProcessingLayout = styled.div`
  padding-top: 100px;
`;

const ProcessingContainer = styled.div`
  margin: 40px 0 140px 0;
  text-align: center;

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }

  > .section-container {
    padding: 120px 207px 140px 207px;
    background-color: ${COLORS.PROCESSING_CONTAINER};

    @media (max-width: 1399px) {
      padding-top: 100px;
      padding-bottom: 115px;
    }

    @media (max-width: 1199px) {
      padding-left: 104px;
      padding-right: 104px;
    }

    @media (max-width: 991px) {
      padding-left: 36px;
      padding-right: 36px;
    }

    @media (max-width: 767px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .heading {
    margin: 0 auto 60px auto;
    max-width: 587px;
    width: 100%;

    @media (max-width: 1399px) {
      margin-bottom: 50px;
      max-width: 550px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 34px;
      max-width: 440px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }

    @media (max-width: 374px) {
      font-size: 20px;
    }
  }

  .progress-value {
    @media (max-width: 767px) {
      font-size: 24px;
    }

    @media (max-width: 575px) {
      font-size: 22px;
    }

    @media (max-width: 374px) {
      font-size: 20px;
    }
  }

  .description {
    color: ${COLORS.PROCESSING_DESCRIPTION};
    min-height: 48px;

    @media (max-width: 767px) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 575px) {
      max-width: unset;
    }

    @media (max-width: 374px) {
      font-size: 12px;
    }
  }
`;

const Processing = ({ location }: { location: any }): JSX.Element => {
  const { t } = useTranslation();
  const descriptionKeys = ['processing-form', 'analyzing-terms', 'checking-potential-bank-offers'];
  const [index, setIndex] = useState(0);
  const [progressValue, setProgressValue] = useState(10);
  const [data, setData] = useState<FormData>();
  const [response, setResponse] = useState<Response>();

  const randomProgress = (): number => Math.floor(Math.random() * (10 - 1)) + 1;

  useEffect(() => {
    if (location && location.state) {
      setData(location.state.payload.data);
      if (data) {
        axios.post(`${process.env.GATSBY_REST_API_ENDPOINT}/investment-offer`, {
          productKind: data.productKind,
          status: data.status,
          estatePrice: Number(replaceCommaWithDot(data.estatePrice)),
          area: Number(replaceCommaWithDot(data.area)),
          serviceFee: Number(replaceCommaWithDot(data.serviceFee)),
          yearOfConstruction: Number(data.yearOfConstruction),
          currency: data.currency,
          leaseRate: Number(replaceCommaWithDot(data.leaseRate)),
          localization: data.localization,
          link: data.link,
        })
          .then((apiResponse) => {
            setResponse(apiResponse.data);
          })
          .catch(() => {
            navigate('/error/');
          });
      }
    }
  }, [data, location]);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (location && location.state) {
        if (progressValue >= 100) {
          clearInterval(progressInterval);
          if (data && response) {
            navigate('/summary/', {
              state: {
                payload: {
                  data: {
                    ...data,
                  },
                  response: {
                    ...response,
                  },
                },
              },
            });
          }
          return;
        }
        // TODO MAKE PROGRESS BAR MORE 'FLUID'
        setProgressValue((previous) => {
          if (previous + randomProgress() >= 90) {
            return 100;
          }
          return previous + randomProgress();
        });
        if (progressValue >= 30) {
          setIndex(1);
        }
        if (progressValue >= 45) {
          setIndex(2);
        }
      } else {
        clearInterval(progressInterval);
        navigate('/');
      }
    }, 1000);
    return (): void => {
      clearInterval(progressInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, location, progressValue]);

  return (
    <Layout>
      <NavigationBar
        darkMenuIcon
        initialValue="NONE"
      />
      <ProcessingLayout>
        <ProcessingContainer>
          <div className="section-container">
            <h2 className="heading">{ t('processing:title') }</h2>
            <ProgressBar completed={progressValue} />
            <h2 className="progress-value">{`${progressValue}%`}</h2>
            <p className="description">{ t(`processing:${descriptionKeys[index]}`) }</p>
          </div>
        </ProcessingContainer>
      </ProcessingLayout>
      <ContactForm />
      <Footer />
    </Layout>
  );
};

export default Processing;
